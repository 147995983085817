<template>
    <div class="jnpxfather">
    <div class="jnpx"
         v-infinite-scroll="loadData"
         infinite-scroll-disabled="loading"
         :infinite-scroll-immediate-check="true"
         infinite-scroll-distance="10" >
        <JiNengPeiXunCard v-for="(item,index) in datas" :key="index" :reqItem="item" />

        <div v-show="loading"  style="text-align: center;font-size: 13px;width: 100%;">加载中...</div>
        <!-- 返回home-->
        <div class="reqlist_back_home" v-on:click="toHome">
            <img src="assets/back_home.png"/>
        </div>
    </div>
    </div>
</template>

<script>
    import JiNengPeiXunCard from '../../xyg/card/JiNengPeiXunCard'

    export default {
        name: "JiNengPeixun",
        data(){
            return{
                loading:false,
                datas:[],
                currentIdx:1,
                hasData:true,
            }
        },
        components:{
            JiNengPeiXunCard
        },
        mounted() {
            this.init();
        },
        methods:{
            init(){
                document.title="技能培训";
                this.loadData();
            },
            loadData(){
                console.log('hasData begin',this.hasData);
                if(!this.hasData) return;
                if(this.loading) return;
                this.loading=true;

                let reqUrl="/api/other/skill";
                let data={size:10,current:this.currentIdx};
                console.log('data:',data);
                this.$post(reqUrl,data)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            let rows=rsp.data.rows;
                            rows.forEach(t=>{
                                this.datas.push(t);
                            })
                            this.currentIdx=this.currentIdx+1;
                            if(rows.length==0) this.hasData=false;
                            console.log('hasData end',this.hasData);
                        }
                        this.loading=false;
                    })
                    .catch(e=>{
                        console.log(e);
                        this.loading=false;
                    })
            },
            toHome(){
                this.$router.push({path:'/'});
            }
        }
    }
</script>

<style scoped>

    .jnpxfather{
        width: 100vw;
        height: 100vh;
        overflow: scroll;
    }

    .jnpx{
        /*height: 100vh;*/
        /*height: 100%;*/
    }
    .jnpxfather::-webkit-scrollbar{
        display: none;
    }
    .reqlist_back_home{
        width: 100px;
        height: 100px;
        background-color: white;
        border-radius: 100px;
        position: fixed;
        bottom: 30px;
        right: 30px;
        box-shadow: 0px 2px 4px rgba(100,100,100,0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .reqlist_back_home img{
        width: 50px;
        height: 50px;
    }
</style>
