<template>
    <div class="JiNengPeiXunCard">
        <div class="JiNengPeiXunCard_container">
<!--            头部信息-->
            <div class="JiNengPeiXunCard_container_header">
                <div class="label">培训名称</div>
                <div class="name">{{ reqItem.name }}</div>
            </div>

<!--            内容-->
            <div class="JiNengPeiXunCard_container_context" v-if="reqItem.pictures!=null
            && reqItem.pictures!=undefined && reqItem.pictures.length>0">
                <img v-for="(img,index) in reqItem.pictures" :key="'img'+index" :src="img" />
<!--                <img src="assets/demo_i1.png"/>-->
<!--                <img src="assets/demo_i2.png" />-->
<!--                <img src="assets/demo_i3.png" />-->
            </div>

<!--            工具-->
            <div class="JiNengPeiXunCard_tools">
                <div class="btnDetail" @click="toDetail(reqItem)">详细</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "JiNengPeiXunCard",
        props:{
            reqItem:Object
        },
        methods:{
            toDetail(){
                this.$router.push({path:'/pxdetail',name:'pxdetail',params:{args:this.reqItem}})
            }
        }
    }
</script>

<style scoped>
    .JiNengPeiXunCard{
        width: 750px;
    }

    .JiNengPeiXunCard_container{
        margin-top: 30px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: white;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
    }

    .JiNengPeiXunCard_container_header{
        display: flex;
        flex-direction: row;
        align-items: center;
        letter-spacing: 2px;
        margin-top: 22px;
        font-size: 26px;
    }
    .JiNengPeiXunCard_container_header .label{
        color: #A79F9F;
        margin-left: 30px;
    }
    .JiNengPeiXunCard_container_header .name{
        margin-left: 16px;
    }

    .JiNengPeiXunCard_container_context{
        border-top: 2px #E4E4E4 dashed;
        border-bottom: 2px #E4E4E4 dashed;
        margin-top: 16px;
        padding-top: 16px;
        padding-left: 30px;
        padding-bottom: 16px;
    }

    .JiNengPeiXunCard_container_context img{
        width: 150px;
        height: 150px;
        margin-right: 20px;
    }

    .JiNengPeiXunCard_tools{
        display: flex;
        flex-direction: row-reverse;
        margin-right: 30px;
    }

    .JiNengPeiXunCard_tools .btnDetail{
        border-radius: 50px;
        border:2px #D3D3D3 solid;
        margin-top: 16px;
        margin-bottom: 16px;
        letter-spacing: 2px;
        color: #A79F9F;
        font-size: 24px;
        width: 120px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

</style>
